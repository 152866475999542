:root {
  --border-radius: 8px;
}

.homeSideBarScrollbar::-webkit-scrollbar {
  width: 6px;
}

.homeSideBarScrollbarBlue::-webkit-scrollbar-track {
  background: #1F87E5;
  border-radius: var(--border-radius)
}

.homeSideBarScrollbarBlue::-webkit-scrollbar-thumb {
  background: #63B4F6;
  border-radius: var(--border-radius);
}

.homeSideBarScrollbarDark::-webkit-scrollbar-track {
  background: #565656;
  border-radius: var(--border-radius);
}

.homeSideBarScrollbarDark::-webkit-scrollbar-thumb {
  background: #7D7D7D;
  border-radius: var(--border-radius);
}

.homeSideBarScrollbarLight::-webkit-scrollbar-track {
  background: #E3F2FD;
  border-radius: var(--border-radius);
}

.homeSideBarScrollbarLight::-webkit-scrollbar-thumb {
  background: #BBDEFB;
  border-radius: var(--border-radius);
}