.ai-reply ul {
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
}

.ai-reply li {
  margin-bottom: 16px;
  list-style: none;
  padding-left: 0;
}
