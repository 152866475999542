/* 
  Class to be applied on as most-outer as possible components to work in
  CSS border-box manner without introducing UI regressions in other parts of the application
*/
.jugl__border-box-component,
.jugl__border-box-component * {
  box-sizing: border-box;
}

/* 
  Ensures that long words break onto the next line instead of overflowing the container
*/

.jugl__break-word {
  word-break: break-word;
}

/* 
   Disable browser default style for autocomplete
*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/* 
  Hide container's scrollbar
*/

/* Chrome, Safari, Opera, and other WebKit-based browsers */
.jugl__hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.jugl__hidden-scrollbar {
  /* IE (Internet Explorer) and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;
}

.jugl__focusable-outline {
  @apply focus-visible:outline-primary-300 focus-visible:outline focus-visible:outline-2;
}

/*
  Number input without arrows
*/
input.jugl__hide-number-arrows::-webkit-outer-spin-button,
input.jugl__hide-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.jugl__hide-number-arrows[type="number"] {
  -moz-appearance: textfield;
}

/*
  Custom Jugl scrollbar
*/

.jugl__custom-scrollbar::-webkit-scrollbar {
  @apply w-2;
}

.jugl__custom-scrollbar-horizontal::-webkit-scrollbar {
  @apply h-2 w-auto;
}

.jugl__custom-scrollbar::-webkit-scrollbar-track,
.jugl__custom-scrollbar-horizontal::-webkit-scrollbar-track {
  @apply bg-grey-100 rounded-lg;
}

.jugl__custom-scrollbar::-webkit-scrollbar-thumb,
.jugl__custom-scrollbar-horizontal::-webkit-scrollbar-thumb {
  @apply bg-grey-300 rounded-lg;
}

.jugl__custom-scrollbar::-webkit-scrollbar-thumb:hover,
.jugl__custom-scrollbar-horizontal::-webkit-scrollbar-thumb:hover {
  @apply bg-grey-400;
}

/* 
  Range slider style
*/

input.jugl__range-slider[type="range"] {
  width: 100%;
}

input.jugl__range-slider[type="range"] {
  color: #2196f3;
  --thumb-height: 1.125em;
  --track-height: 0.125em;
  --track-color: #d9d9d9;
  --clip-edges: 0.125em;
}

input.jugl__range-slider[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input.jugl__range-slider[type="range"]:active {
  cursor: grabbing;
}

input.jugl__range-slider[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

input.jugl__range-slider[type="range"],
input.jugl__range-slider[type="range"]::-webkit-slider-runnable-track,
input.jugl__range-slider[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input.jugl__range-slider[type="range"]::-webkit-slider-runnable-track,
input.jugl__range-slider[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input.jugl__range-slider[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input.jugl__range-slider[type="range"]:hover::-webkit-slider-thumb {
  cursor: grab;
}

input.jugl__range-slider[type="range"]:active::-webkit-slider-thumb {
  cursor: grabbing;
}

input.jugl__range-slider[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

input.jugl__range-slider[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

input.jugl__range-slider[type="range"],
input.jugl__range-slider[type="range"]::-moz-range-track,
input.jugl__range-slider[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input.jugl__range-slider[type="range"]::-moz-range-track,
input.jugl__range-slider[type="range"]::-moz-range-thumb,
input.jugl__range-slider[type="range"]::-moz-range-progress {
  background: #fff0;
}

input.jugl__range-slider[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input.jugl__range-slider[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

input.jugl__range-slider[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input.jugl__range-slider[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

input.jugl__range-slider[type="range"]::-moz-range-track,
input.jugl__range-slider[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input.jugl__range-slider[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
